<template>
    <div>
        <div class="form-search-box flex space">
            <div class="flex alcenter">
                <a-button @click="add" type="primary">添加</a-button>
            </div>
            <div>
                <a-form layout="inline">
                    <a-form-item label="搜索">
                        <a-input v-model="search.keyword" style="width: 120px"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="mt20 pd20 bg-w">
            <div class="xhm-table-gray mt20">
                <a-table rowKey="customer_service_id" :columns="columns" :pagination="pagination" @change="change"
                    :data-source="list" :loading="loading">
                    <!-- 微信二维码 -->
                    <template slot="wx_qr" slot-scope="record,index">
                        <img width="100" :src="record.wx_qr">
                    </template>
                    <template slot="action" slot-scope="record,index">
                        <a-button type="link" @click="edit(record)">修改</a-button>
                    </template>
                </a-table>
            </div>
        </div>

        <a-modal width="400px" :title="title" :visible="show" @cancel="show = false" @ok="save">
            <a-form-model :model="data" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="姓名">
                    <a-input v-model="data.name" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="手机号">
                    <a-input v-model="data.mobile" style="width: 200px;" />
                </a-form-model-item>

                <a-form-model-item label="微信号">
                    <a-input v-model="data.weixin" style="width: 200px;" />
                </a-form-model-item>

                <a-form-model-item label="微信二维码">
                    <a-input v-model="data.wx_qr" style="width: 200px;" />
                </a-form-model-item>

                <a-form-model-item label="备注">
                    <a-textarea v-model="data.notes" style="width: 200px;" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { apiCustomerServiceList, apiCustomerServiceSave } from '../../utils/api/account';
import orderList from '../custom/order.vue';
export default {
    components: {
        orderList
    },
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                keyword: '',
                data: ['', '']
            },
            show: false,
            title: '添加账号',
            data: {
                account_id: 0,
                name: '',
                mobile: '',
                pwd: '',
                role: 0,
                num: 1,
                status: 1,
            },
            accountData: {},
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '客服id', dataIndex: 'customer_service_id', align: 'center' },
                { title: '名字', dataIndex: 'name', align: 'center' },
                { title: '手机号', dataIndex: 'mobile', align: 'center' },
                { title: '微信', dataIndex: 'weixin', align: 'center' },
                { title: '微信二维码', key: 'wx_qr', align: 'center', scopedSlots: { customRender: 'wx_qr' } },
                { title: '备注', dataIndex: 'notes', align: 'center', width:200 },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            list: [],
            orderData: {}
        }
    },
    created() {
        this.getList();
    },
    methods: {
        add() {
            this.title = '添加客服账号';
            this.show = true;
            this.data = this.data;
        },
        edit(detail) {
            this.show = true;
            detail.pwd = '';
            this.data = detail;
        },
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiCustomerServiceList({
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                keyword: this.search.keyword,
            }).then(res => {
                this.pagination.total = res.total;
                this.list = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        save() {//提交
            apiCustomerServiceSave(this.data).then(res => {
                this.show = false;
                this.$message.success('成功');
                this.getList();
            })
        },
        changeOperateTime() {
            this.search.data = value;
        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
        searchAct() {
            this.getList();
        },
        cancelAct() {
            this.search = {
                keyword: '',
                data: ['', ''],
                designer_id: 0
            };
            this.getList();
        }
    }
}
</script>

<style></style>
